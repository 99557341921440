import Div from "../Div"
import Spacing from "../Spacing"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { CheckCircle } from "phosphor-react"
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext"
import { FileInputPreview } from "./FileInputPreview"
import { Loading } from "../loading"
import { sendRepairs } from "../../api/send-repairs"

export function ReparosForm() {
  const { keepLastPeqReparos } = useSolicitationStorage()
  const [sendingButton, setSendingButton] = useState("Solicitar benefício")
  const [reparosStatus, setreparosStatus] = useState(null)

  const [eidField, setEidField] = useState(null)

  const [isSearchingCity, setIsSearchingCity] = useState(false)
  const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)
  const [isSearchingVehicule, setIsSearchingVehicule] = useState(false)

  const [reparoscnpj, setreparosCnpj] = useState(null)
  const [reparossolicitante, setreparosSolicitante] = useState(null)
  const [reparostelefone, setreparosTelefone] = useState(null)
  const [reparosemail, setreparosEmail] = useState(null)

  const [reparosnomesolicitante, setreparosnomesolicitante] = useState(null)
  const [reparoscpfsolicitante, setreparoscpfsolicitante] = useState(null)
  const [reparosemailsolicitante, setreparosemailsolicitante] = useState(null)
  const [reparostelefonesolicitante, setreparostelefonesolicitante] =
    useState(null)

  const [reparosplaca, setreparosplaca] = useState(null)
  const [reparoschassi, setreparoschassi] = useState(null)
  const [_, setreparostipo] = useState(null)
  const [reparosmarca, setreparosmarca] = useState([])
  const [reparosmodelo, setreparosmodelo] = useState([])
  const [reparosanofabricacao, setreparosanofabricacao] = useState(null)
  const [reparosanomodelo, setreparosanomodelo] = useState(null)
  const [reparosestado, setreparosestado] = useState([])
  const [reparoscidade, setreparoscidade] = useState([])
  const [__, setreparospecadanificada] = useState(null)
  const [reparospecaareparar, setreparospecaareparar] = useState(null)

  const [reparosfotosvidrodanificado, setreparosfotosvidrodanificado] =
    useState()
  const [reparosfotosvistoriaprevia, setreparosfotosvistoriaprevia] = useState()

  const fetchMarcas = async () => {
    await fetch("https://nvo.brasilbeneficios.club/api/get-marcas")
      .then((res) => res.json())
      .then((data) => {
        setreparosmarca(data.data)
      })
  }

  function handleMarcaChange(e) {
    setIsSearchingVehicule(true)
    fetch(`https://nvo.brasilbeneficios.club/api/get-modelos/${e.target.value}`)
      .then((res) => res.json())
      .then((data) => {
        setreparosmodelo(data.data.Modelos)
      })
      .finally(() => setIsSearchingVehicule(false))
  }

  const fetchStates = async () => {
    await fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((res) => res.json())
      .then((data) => setreparosestado(data))
  }

  function handleStateChange(e) {
    setIsSearchingCity(true)
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${e.target.value}/municipios`
    )
      .then((res) => res.json())
      .then((data) => setreparoscidade(data))
      .finally(() => setIsSearchingCity(false))
  }

  useEffect(() => {
    fetchStates()
    fetchMarcas()
  }, [])

  const handleCNPJ = () => {
    if (reparoscnpj.length !== 18 && reparoscnpj.length !== 0) {
      toast.error("Campo CNPJ invalido.")
    } else {
      setIsSearchingCnpj(true)

      async function postJSON(data) {
        try {
          const response = await fetch(
            "https://brasilbeneficios.club/bb/f-empresa",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          )

          const result = await response.json()
          setEidField(result.IDCodigo)
          setreparosEmail(result.Email)
          setreparosSolicitante(result.RazaoSocial)
          setreparosTelefone(result.Telefone)
        } catch (error) {
          toast.error("CNPJ invalido ou empresa nao encontrada.")
        }
      }

      postJSON({ CPFCNPJ: reparoscnpj })

      setIsSearchingCnpj(false)
    }
  }

  const handlePeqReparosSubmit = async (event) => {
    event.preventDefault()
    setSendingButton("Enviando...")

    const formData = new FormData(event.currentTarget)

    await sendRepairs(formData)

    fetch(
      "https://brasilbeneficios.club/bb/area-cliente/pequenos-reparos/send",
      {
        method: "POST",
        mode: "no-cors",
        credentials: "same-origin",
        body: formData,
      }
    ).then((res) => {
      toast.success(
        "Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!"
      )

      for (const data of formData.entries()) {
        localStorage.setItem(data[0], data[1])
      }

      setreparosStatus("Sended")
      localStorage.setItem("lastSolicitatedService", "PequenosReparos")
    })
  }

  return (
    <>
      {reparosStatus === null ? (
        <Div className="fade-in col-lg-8">
          {keepLastPeqReparos ? (
            <form
              onSubmit={handlePeqReparosSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input
                type="hidden"
                name="IDEmpresa"
                value={localStorage.getItem("IDEmpresa")}
              />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setreparosCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                    value={localStorage.getItem("CNPJ")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CNPJ"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={localStorage.getItem("SolicitanteEmpresa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setreparosTelefone(e.target.value)}
                    value={localStorage.getItem("TelefoneEmpresa")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setreparosEmail(e.target.value)}
                    value={localStorage.getItem("EmailEmpresa")}
                    name="EmailEmpresa"
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Nome do cliente:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosnomesolicitante(e.target.value)}
                    value={localStorage.getItem("Nome")}
                    name="Nome"
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">CPF do cliente:</label>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=""
                    placeholder="___.___.___---"
                    onChange={(e) => setreparoscpfsolicitante(e.target.value)}
                    value={localStorage.getItem("CPF")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CPF"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail do cliente:</label>
                  <input
                    type="email"
                    onChange={(e) => setreparosemailsolicitante(e.target.value)}
                    name="Email"
                    value={localStorage.getItem("Email")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Telefone do cliente:
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) =>
                      setreparostelefonesolicitante(e.target.value)
                    }
                    value={localStorage.getItem("Telefone")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="Telefone"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Placa:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosplaca(e.target.value)}
                    name="Placa"
                    value={localStorage.getItem("Placa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Chassi:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparoschassi(e.target.value)}
                    name="Chassi"
                    value={localStorage.getItem("Chassi")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Tipo:</label>
                  <select
                    className="cs-form_field"
                    name="Tipo"
                    onChange={(e) => setreparostipo(e.target.value)}
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Automovel"
                      selected={
                        localStorage.getItem("Tipo") === "Automovel"
                          ? true
                          : false
                      }
                    >
                      Automovel
                    </option>
                    <option
                      value="Motocicleta"
                      selected={
                        localStorage.getItem("Tipo") === "Motocicleta"
                          ? true
                          : false
                      }
                    >
                      Motocicleta
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Marca do veículo:</label>
                  <select
                    onChange={(e) => handleMarcaChange(e)}
                    name="Fabricante"
                    className="cs-form_field"
                  >
                    <option value="">Selecione uma marca</option>
                    {reparosmarca.map((marca) => {
                      return (
                        <option
                          key={marca.Value}
                          value={marca.Value}
                          selected={
                            localStorage.getItem("Fabricante") === marca.Value
                              ? true
                              : false
                          }
                        >
                          {marca.Label}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">
                      Modelo do veículo:
                    </label>
                    {isSearchingVehicule && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <select
                    className="cs-form_field"
                    name="modelo"
                    disabled={isSearchingVehicule ? true : false}
                  >
                    <option value="">Selecione um modelo</option>
                    {reparosmodelo.map((modelo) => {
                      return (
                        <option
                          key={modelo.Value}
                          value={modelo.Value}
                          selected={
                            localStorage.getItem("modelo") === modelo.Value
                              ? true
                              : false
                          }
                        >
                          {modelo.Label}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Ano Fabricacao:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosanofabricacao(e.target.value)}
                    name="AnoFabricacao"
                    value={localStorage.getItem("AnoFabricacao")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Ano Modelo:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosanomodelo(e.target.value)}
                    name="AnoModelo"
                    value={localStorage.getItem("AnoModelo")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Estado:</label>
                  <select
                    onChange={(e) => handleStateChange(e)}
                    name="Estado"
                    className="cs-form_field"
                  >
                    <option value="">Selecione um Estado</option>
                    {reparosestado.map((state) => {
                      return (
                        <option
                          key={state.id}
                          value={state.sigla}
                          selected={
                            localStorage.getItem("Estado") === state.sigla
                              ? true
                              : false
                          }
                        >
                          {state.nome}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">Cidade:</label>
                    {isSearchingCity && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <select
                    onChange={(e) => null}
                    name="Cidade"
                    disabled={isSearchingCity ? true : false}
                    className="cs-form_field"
                  >
                    <option value="">Selecione uma Cidade</option>
                    {reparoscidade.map((city) => {
                      return (
                        <option
                          key={city.id}
                          value={city.sigla}
                          selected={
                            localStorage.getItem("Cidade") === city.sigla
                              ? true
                              : false
                          }
                        >
                          {city.nome}
                        </option>
                      )
                    })}
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Peça Danificada:</label>
                  <select
                    className="cs-form_field"
                    name="Peca"
                    onChange={(e) => setreparospecadanificada(e.target.value)}
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="Assistencia contra buracos"
                      selected={
                        localStorage.getItem("Peca") ===
                        "Assistencia contra buracos"
                          ? true
                          : false
                      }
                    >
                      Assistencia contra buracos
                    </option>
                    <option
                      value="Assistencia FRL"
                      selected={
                        localStorage.getItem("Peca") === "Assistencia FRL"
                          ? true
                          : false
                      }
                    >
                      Assistencia FRL
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Peça a ser reparada:
                  </label>
                  <textarea
                    onChange={(e) => setreparospecaareparar(e.target.value)}
                    name="PecaSerReparadaSinistro"
                    value={localStorage.getItem("PecaSerReparadaSinistro")}
                    className="cs-form_field"
                    cols="30"
                    rows="3"
                  ></textarea>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Fotos do Vidro Danificado:
                  </label>
                  <FileInputPreview
                    file={reparosfotosvidrodanificado}
                    name="Anexo2"
                    setFile={setreparosfotosvidrodanificado}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Fotos da Vistoria Prévia do Veículo:
                  </label>
                  <FileInputPreview
                    file={reparosfotosvistoriaprevia}
                    name="Anexo3"
                    setFile={setreparosfotosvistoriaprevia}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <button
                className="fade-in cs-btn cs-style1 cs-type1"
                type="submit"
              >
                <span>{sendingButton}</span>
              </button>
            </form>
          ) : (
            <form
              onSubmit={handlePeqReparosSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input type="hidden" name="IDEmpresa" value={eidField} />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setreparosCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CNPJ"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setreparosSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={reparossolicitante ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setreparosTelefone(e.target.value)}
                    value={reparostelefone ?? ""}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setreparosEmail(e.target.value)}
                    value={reparosemail ?? ""}
                    name="EmailEmpresa"
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              {reparosemail && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">Nome do cliente:</label>
                    <input
                      type="text"
                      onChange={(e) =>
                        setreparosnomesolicitante(e.target.value)
                      }
                      value={reparosnomesolicitante ?? ""}
                      name="Nome"
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">CPF do cliente:</label>
                    <InputMask
                      mask="999.999.999-99"
                      maskChar=""
                      placeholder="___.___.___---"
                      onChange={(e) => setreparoscpfsolicitante(e.target.value)}
                      value={reparoscpfsolicitante ?? ""}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          name="CPF"
                          className="cs-form_field"
                        />
                      )}
                    </InputMask>
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      E-mail do cliente:
                    </label>
                    <input
                      type="email"
                      onChange={(e) =>
                        setreparosemailsolicitante(e.target.value)
                      }
                      name="Email"
                      value={reparosemailsolicitante ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Telefone do cliente:
                    </label>
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar=""
                      placeholder="(__) _ ____-____"
                      onChange={(e) =>
                        setreparostelefonesolicitante(e.target.value)
                      }
                      value={reparostelefonesolicitante ?? ""}
                    >
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          name="Telefone"
                          className="cs-form_field"
                        />
                      )}
                    </InputMask>
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {reparostelefonesolicitante && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">Placa:</label>
                    <input
                      type="text"
                      onChange={(e) => setreparosplaca(e.target.value)}
                      name="Placa"
                      value={reparosplaca ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Chassi:</label>
                    <input
                      type="text"
                      onChange={(e) => setreparoschassi(e.target.value)}
                      name="Chassi"
                      value={reparoschassi ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Tipo:</label>
                    <select
                      className="cs-form_field"
                      name="Tipo"
                      onChange={(e) => setreparostipo(e.target.value)}
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="Automóvel">Automovel</option>
                      <option value="Motocicleta">Motocicleta</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Marca do veículo:
                    </label>
                    <select
                      onChange={(e) => handleMarcaChange(e)}
                      name="Fabricante"
                      className="cs-form_field"
                    >
                      <option value="">Selecione uma marca</option>
                      {reparosmarca.map((marca) => {
                        return (
                          <option key={marca.Value} value={marca.Value}>
                            {marca.Label}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <div className="d-flex align-items-center gap-4">
                      <label className="form_field_label">
                        Modelo do veículo:
                      </label>
                      {isSearchingVehicule && (
                        <div className="mb-2">
                          <Loading size="2px" />
                        </div>
                      )}
                    </div>
                    <select
                      className="cs-form_field"
                      name="modelo"
                      disabled={isSearchingVehicule ? true : false}
                    >
                      <option value="">Selecione um modelo</option>
                      {reparosmodelo.map((modelo) => {
                        return (
                          <option key={modelo.Value} value={modelo.Value}>
                            {modelo.Label}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Ano Fabricacao:</label>
                    <input
                      type="text"
                      onChange={(e) => setreparosanofabricacao(e.target.value)}
                      name="AnoFabricacao"
                      value={reparosanofabricacao ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Ano Modelo:</label>
                    <input
                      type="text"
                      onChange={(e) => setreparosanomodelo(e.target.value)}
                      name="AnoModelo"
                      value={reparosanomodelo ?? ""}
                      className="cs-form_field"
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">Estado:</label>
                    <select
                      onChange={(e) => handleStateChange(e)}
                      name="Estado"
                      className="cs-form_field"
                    >
                      <option value="">Selecione um Estado</option>
                      {reparosestado.map((state) => {
                        return (
                          <option key={state.id} value={state.sigla}>
                            {state.nome}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <div className="d-flex align-items-center gap-4">
                      <label className="form_field_label">Cidade:</label>
                      {isSearchingCity && (
                        <div className="mb-2">
                          <Loading size="2px" />
                        </div>
                      )}
                    </div>
                    <select
                      onChange={(e) => null}
                      name="Cidade"
                      disabled={isSearchingCity ? true : false}
                      className="cs-form_field"
                    >
                      <option value="">Selecione uma Cidade</option>
                      {reparoscidade.map((city) => {
                        return (
                          <option key={city.id} value={city.sigla}>
                            {city.nome}
                          </option>
                        )
                      })}
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Tipo de Assistência:
                    </label>
                    <select
                      className="cs-form_field"
                      name="Peca"
                      onChange={(e) => setreparospecadanificada(e.target.value)}
                    >
                      <option value="">Selecione uma opcao</option>
                      <option value="against-holes">Contra buracos</option>
                      <option value="frl">FRL</option>
                    </select>
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Peça a ser reparada:
                    </label>
                    <textarea
                      onChange={(e) => setreparospecaareparar(e.target.value)}
                      name="PecaSerReparadaSinistro"
                      value={reparospecaareparar ?? ""}
                      className="cs-form_field"
                      cols="30"
                      rows="3"
                    ></textarea>
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {reparospecaareparar && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Fotos do Vidro Danificado:
                    </label>
                    <FileInputPreview
                      file={reparosfotosvidrodanificado}
                      name="Anexo2"
                      setFile={setreparosfotosvidrodanificado}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Fotos da Vistoria Prévia do Veículo:
                    </label>
                    <FileInputPreview
                      file={reparosfotosvistoriaprevia}
                      name="Anexo3"
                      setFile={setreparosfotosvistoriaprevia}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {reparosfotosvistoriaprevia && (
                <button
                  className="fade-in cs-btn cs-style1 cs-type1"
                  type="submit"
                >
                  <span>{sendingButton}</span>
                </button>
              )}
            </form>
          )}
        </Div>
      ) : (
        <Div>
          <CheckCircle size={50} />
          <h5 className="mt-3">Sucesso!</h5>
          <p>
            Sua solicitação foi realizada com sucesso!
            <br />
            Em até 48 horas entraremos em contato para fazer a liberação do seu
            benefício!
          </p>
          <button
            className="fade-in cs-btn cs-style1 cs-type1"
            type="button"
            onClick={(e) => window.location.reload()}
          >
            <span>Realizar outra solicitacao</span>
          </button>
        </Div>
      )}
    </>
  )
}

import React, { useEffect } from "react"
import { pageTitle } from "../../helper"
import Hero7 from "../Hero/Hero7"
import {
  Bank,
  Binoculars,
  Car,
  CarSimple,
  File,
  FirstAid,
  Heart,
  Heartbeat,
  House,
  InstagramLogo,
  List,
  WhatsappLogo,
  Wrench,
  Phone,
  Bicycle,
  PawPrint,
  HouseLine,
  FlowerLotus,
} from "phosphor-react"
import { basepath } from "../../App"
import { WhatsApp } from "../whatsapp"
import { Truck } from "lucide-react"
import { sendAccessCount } from "../../api/send-access-count"

export default function CaseStudyShowcaseHome() {
  pageTitle("Início")

  useEffect(() => {
    window.scrollTo(0, 0)
    sendAccessCount()
  }, [])

  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "/",
      icon: <InstagramLogo size={30} />,
    },
    {
      name: "WhatsApp",
      links: "/",
      icon: <WhatsappLogo size={30} />,
    },
  ]

  const showcaseData = [
    {
      side_title: (
        <>
          Bem
          <br />
          Vindo
        </>
      ),
      title: "Bem-vindo!",
      description: (
        <>
          <h4>NÓS SOMOS UMA EMPRESA DE GESTÃO DE BENEFÍCIOS</h4>
          <p>
            Em nossas linhas de serviços personalizamos seu programa com os
            benefícios
            <br />
            que se encaixam nas necessidades de seus associados.
          </p>
        </>
      ),
      // extra: <><HomeSliderExtraContent /></>,
      video: `${basepath}/video.mp4`,
      imgUrl: `${basepath}/img/_1.webp`,
      href: `${basepath}`,
      aboutButton: false,
    },
    {
      title: "Assistências",
      imgUrl: `${basepath}/img/gallery_images/${
        window.innerWidth >= 800 ? "assistencia" : "assistencia_mobile"
      }.webp`,
      description: "Veja as assistências que podemos oferecer!",
      menu_button: true,
      baloons: [
        {
          id: 1,
          title: "Carro",
          menu: [
            {
              id: 1,
              title: "Assistência24hs",
              link: `${basepath}/assistencia24`,
              icon: (
                <>
                  <Phone size={22} />
                </>
              ),
            },
            {
              id: 2,
              title: "CarroReserva",
              link: `${basepath}/carroreserva`,
              icon: (
                <>
                  <Car size={22} />
                </>
              ),
            },
            {
              id: 3,
              title: "Vidro",
              link: `${basepath}/vidro`,
              icon: (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#000000"
                    viewBox="0 0 256 256"
                  >
                    <path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16h64a8,8,0,0,0,7.59-5.47l14.83-44.48L163,151.43a8.07,8.07,0,0,0,4.46-4.46l14.62-36.55,44.48-14.83A8,8,0,0,0,232,88V56A16,16,0,0,0,216,40ZM112.41,157.47,98.23,200H40V172l52-52,30.42,30.42L117,152.57A8,8,0,0,0,112.41,157.47ZM216,82.23,173.47,96.41a8,8,0,0,0-4.9,4.62l-14.72,36.82L138.58,144l-35.27-35.27a16,16,0,0,0-22.62,0L40,149.37V56H216Zm12.68,33a8,8,0,0,0-7.21-1.1l-23.8,7.94a8,8,0,0,0-4.9,4.61l-14.31,35.77-35.77,14.31a8,8,0,0,0-4.61,4.9l-7.94,23.8A8,8,0,0,0,137.73,216H216a16,16,0,0,0,16-16V121.73A8,8,0,0,0,228.68,115.24ZM216,200H148.83l3.25-9.75,35.51-14.2a8.07,8.07,0,0,0,4.46-4.46l14.2-35.51,9.75-3.25Z"></path>
                  </svg>
                </>
              ),
            },
            {
              id: 4,
              title: "PequenosReparos",
              link: `${basepath}/pequenosreparos`,
              icon: (
                <>
                  <Wrench size={22} />
                </>
              ),
            },
          ],
          icon: <CarSimple size={40} color="#0987FF" />,
        },
        {
          id: 2,
          title: "Casa",
          menu: [
            {
              id: 1,
              title: "Residencial",
              link: `${basepath}/residencial`,
              icon: (
                <>
                  <HouseLine size={22} />
                </>
              ),
            },
            {
              id: 2,
              title: "Pet",
              link: `${basepath}/pet`,
              icon: (
                <>
                  <PawPrint size={22} />
                </>
              ),
            },
            {
              id: 3,
              title: "Bike",
              link: `${basepath}/bike`,
              icon: (
                <>
                  <Bicycle size={22} />
                </>
              ),
            },
          ],
          icon: <House size={40} color="#FFCC29" />,
        },
        {
          id: 3,
          title: "VidaSaude",
          menu: [
            {
              id: 1,
              title: "Funeral",
              link: `${basepath}/funeral`,
              icon: (
                <>
                  <FlowerLotus size={22} />
                </>
              ),
            },
            {
              id: 2,
              title: "Odonto",
              link: `${basepath}/odonto`,
              icon: (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#000000"
                    viewBox="0 0 256 256"
                  >
                    <path d="M171,71.42,149.54,80,171,88.57A8,8,0,1,1,165,103.42L128,88.61,91,103.42A8,8,0,1,1,85,88.57L106.46,80,85,71.42A8,8,0,1,1,91,56.57l37,14.81,37-14.81A8,8,0,1,1,171,71.42Zm53,8.33c0,42.72-8,75.4-14.69,95.28-8.73,25.8-20.63,45.49-32.65,54a15.69,15.69,0,0,1-15.95,1.41,16.09,16.09,0,0,1-9.18-13.36C150.68,205.58,146.48,168,128,168s-22.68,37.59-23.53,49.11a16.09,16.09,0,0,1-16,14.9,15.67,15.67,0,0,1-9.13-2.95c-12-8.53-23.92-28.22-32.65-54C40,155.15,32,122.47,32,79.75A56,56,0,0,1,88,24h80A56,56,0,0,1,224,79.75Zm-16,0A40,40,0,0,0,168,40H88A40,40,0,0,0,48,79.76c0,40.55,7.51,71.4,13.85,90.14,11.05,32.66,23,43.37,26.61,46C91.57,174.67,105.59,152,128,152s36.45,22.71,39.49,63.94h0c3.6-2.59,15.57-13.26,26.66-46C200.49,151.16,208,120.31,208,79.76Z"></path>
                  </svg>
                </>
              ),
            },
            {
              id: 3,
              title: "Telemedicina",
              link: `${basepath}/telemedicina`,
              icon: (
                <>
                  <FirstAid size={22} />
                </>
              ),
            },
          ],
          icon: <Heart size={40} color="#E33E2B" />,
        },
      ],
      href: "/",
    },
    {
      title: "Seguros",
      imgUrl: `${basepath}/img/gallery_images/seguros.webp`,
      description: "Veja os seguros que podemos oferecer!",
      baloons: [
        {
          id: 1,
          title: "SeguroVida",
          link: `${basepath}/seguro_de_vida`,
          icon: <Heart size={40} color="#E33E2B" />,
        },
        {
          id: 2,
          title: "SeguroResidencial",
          link: `${basepath}/seguro_residencial`,
          icon: <House size={40} color="#FFCC29" />,
        },
        {
          id: 3,
          title: "SeguroAP",
          link: `${basepath}/seguro_ap`,
          icon: <Heartbeat size={40} color="#E33E2B" />,
        },
        {
          id: 4,
          title: "SeguroAPP",
          link: `${basepath}/seguro_app`,
          icon: <FirstAid size={40} color="#E33E2B" />,
        },
        {
          id: 5,
          title: "SeguroRcv",
          link: `${basepath}/seguro_rcv`,
          icon: <Truck size={40} color="#0987FF" />,
        },
      ],
      href: `${basepath}`,
    },
    {
      title: "Clube de Benefícios",
      description: "Monte seu clube de vantagens em poucos minutos.",
      imgUrl: `${basepath}/img/gallery_images/clube.webp`,
      href: `${basepath}/clube`,
      aboutButton: true,
    },
    {
      title: "BB Auto Consulta",
      imgUrl: `${basepath}/img/gallery_images/bbautoconsulta.webp`,
      baloons: [
        {
          id: 1,
          title: "BuscadeVeículos",
          link: `${basepath}/buscaveiculos`,
          icon: <CarSimple size={40} color="#1877F2" />,
        },
        {
          id: 2,
          title: "BINRF",
          link: `${basepath}/binrf`,
          icon: <List size={40} color="#FFCC29" />,
        },
        {
          id: 3,
          title: "PesquisadeRestrições",
          link: `${basepath}/restricoes`,
          icon: <File size={40} color="#E33E2B" />,
        },
        {
          id: 4,
          title: "PesquisadeDébito",
          link: `${basepath}/debito`,
          icon: <Bank size={40} color="#E33E2B" />,
        },
        {
          id: 5,
          title: "PesquisadeProprietáriosAnteriores",
          link: `${basepath}/proprietarios`,
          icon: <Binoculars size={40} color="#E33E2B" />,
        },
      ],
      href: `${basepath}`,
    },
  ]

  return (
    <>
      <WhatsApp />
      <Hero7
        heroSocialLinks={heroSocialLinks}
        socialLinksHeading=""
        showcaseData={showcaseData}
      />
    </>
  )
}

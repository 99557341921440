import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import SocialWidget from "../Widget/SocialWidget"
import Newsletter from "../Widget/Newsletter"
import "./header.scss"
import ContactInfoWidget from "../Widget/ContactInfoWidget"
import Div from "../Div"
import { basepath } from "../../App"
import { SignIn, Users } from "phosphor-react"

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false)
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    })
  }, [])

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="justify-content-between cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to={basepath}>
                  <img
                    src={`${basepath}/img/bbeneficios_branca.svg`}
                    alt="Logo"
                  />
                </Link>
              </Div>
              <Div className="d-flex gap-2">
                <Link
                  to={`${basepath}/cliente`}
                  className="cs-btn cs-style1 cs-type1"
                >
                  <Users size={22} />
                  <span>Área do Cliente</span>
                </Link>
                <a
                  href={`https://bblp.stellarcode.space`}
                  target="_blank"
                  rel="noreferrer"
                  className="cs-btn cs-style1 cs-type1"
                >
                  <SignIn size={22} />
                  <span>Solicitar Proposta</span>
                </a>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        className={
          sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img src="/images/footer_logo.svg" alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Do you have a project in your <br /> mind? Keep connect us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe"
              subtitle="At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit."
              placeholder="example@gmail.com"
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  )
}

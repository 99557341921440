import InputMask from "react-input-mask"
import Div from "../../Div"
import { Loading } from "../../loading"
import { FileInputPreview } from "../FileInputPreview"
import { useFormContext } from "../CarroReservaForm"

export function RenderFormFields({
  handleCnpjSearch,
  handleCepSearch,
  states,
  cities,
  fetchCities,
  isSearchingCity,
  isSearchingCnpj,
  isSearchingCep,
}) {
  const { formState, dispatch } = useFormContext()

  const handleInputChange = (field, value, disabled = undefined) => {
    dispatch({ type: "UPDATE_FIELD", field, value, disabled })
  }

  return (
    <>
      <Div className="row">
        {/* CNPJ */}
        <Div className="col-lg-6">
          <label className="form_field_label">CNPJ:</label>
          {isSearchingCnpj && <Loading size="2px" />}
          <InputMask
            mask="99.999.999/9999-99"
            value={formState.cnpj?.value || ""}
            disabled={formState.cnpj?.disabled || false}
            onChange={(e) => handleInputChange("cnpj", e.target.value)}
            onBlur={(e) => handleCnpjSearch(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                name="CNPJEmpresa"
                className="cs-form_field"
                required
              />
            )}
          </InputMask>
        </Div>

        {/* Solicitante (Empresa) */}
        <Div className="col-lg-6">
          <label className="form_field_label">Solicitante (Empresa):</label>
          <input
            type="text"
            value={formState.solicitanteEmpresa.value ?? ""}
            disabled={formState.solicitanteEmpresa?.disabled || false}
            onChange={(e) =>
              handleInputChange("solicitanteEmpresa", e.target.value)
            }
            name="solicitanteEmpresa"
            className="cs-form_field"
            required
          />
        </Div>

        {/* Telefone */}
        <Div className="col-lg-6">
          <label className="form_field_label">Telefone (Empresa):</label>
          <InputMask
            mask={
              formState.solicitanteTelefone &&
              formState.solicitanteTelefone.length > 13
                ? "(99) 9 9999-9999"
                : "(99) 9999-9999"
            }
            value={formState.solicitanteTelefone.value || ""}
            disabled={formState.solicitanteTelefone?.disabled || false}
            onChange={(e) =>
              handleInputChange("solicitanteTelefone", e.target.value)
            }
          >
            {(inputProps) => (
              <input
                {...inputProps}
                name="solicitanteTelefone"
                className="cs-form_field"
                disabled={formState.solicitanteTelefone?.disabled || false}
                required
              />
            )}
          </InputMask>
        </Div>

        {/* E-mail */}
        <Div className="col-lg-6">
          <label className="form_field_label">E-mail (Empresa):</label>
          <input
            type="email"
            value={formState.solicitanteEmail?.value ?? ""}
            disabled={formState.solicitanteEmail?.disabled || false}
            onChange={(e) =>
              handleInputChange("solicitanteEmail", e.target.value)
            }
            name="solicitanteEmail"
            className="cs-form_field"
            required
          />
        </Div>

        {/* E-mail da reserva */}
        <Div className="col-lg-6">
          <label className="form_field_label">
            E-mail de retorno da empresa:
          </label>
          <input
            type="email"
            value={formState.solicitanteEmailReserva?.value ?? ""}
            disabled={formState.solicitanteEmailReserva?.disabled || false}
            onChange={(e) =>
              handleInputChange("solicitanteEmailReserva", e.target.value)
            }
            name="solicitanteEmailReserva"
            className="cs-form_field"
            required
          />
        </Div>

        {/* Nome (Cliente) */}
        <Div className="col-lg-6">
          <label className="form_field_label">Nome (Cliente):</label>
          <input
            type="text"
            value={formState.clienteNome.value ?? ""}
            onChange={(e) => handleInputChange("clienteNome", e.target.value)}
            name="Nome"
            className="cs-form_field"
            required
          />
        </Div>

        {/* CPF (Cliente) */}
        <Div className="col-lg-6">
          <label className="form_field_label">CPF (Cliente):</label>
          <InputMask
            mask="999.999.999-99"
            value={formState.clienteCpf.value ?? ""}
            onChange={(e) => handleInputChange("clienteCpf", e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                name="CPF"
                className="cs-form_field"
                required
              />
            )}
          </InputMask>
        </Div>

        {/* E-mail (Cliente) */}
        <Div className="col-lg-6">
          <label className="form_field_label">E-mail (Cliente):</label>
          <input
            type="email"
            value={formState.clienteEmail.value ?? ""}
            onChange={(e) => handleInputChange("clienteEmail", e.target.value)}
            name="Email"
            className="cs-form_field"
            required
          />
        </Div>

        {/* Telefone (Cliente) */}
        <Div className="col-lg-6">
          <label className="form_field_label">Telefone (Cliente):</label>
          <input
            type="number"
            value={formState.clienteTelefone.value ?? ""}
            onChange={(e) =>
              handleInputChange("clienteTelefone", e.target.value)
            }
            name="Telefone"
            className="cs-form_field"
            required
          />
        </Div>

        {/* CEP */}
        <Div className="col-lg-6">
          <label className="form_field_label">CEP:</label>
          <InputMask
            mask="99999-999"
            value={formState.cep.value ?? ""}
            onChange={(e) => handleInputChange("cep", e.target.value)}
            onBlur={(e) => handleCepSearch(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                name="CEP"
                className="cs-form_field"
                required
              />
            )}
          </InputMask>
        </Div>

        {formState.cep && (
          <Div className="row fade-in">
            <Div className="col-lg-6">
              <label className="form_field_label">Logradouro:</label>
              <input
                type="text"
                value={formState.Logradouro.value ?? ""}
                onChange={(e) =>
                  handleInputChange("Logradouro", e.target.value)
                }
                name="Logradouro"
                className="cs-form_field"
                required
              />
            </Div>

            <Div className="col-lg-6">
              <label className="form_field_label">Bairro:</label>
              <input
                type="text"
                value={formState.Bairro.value ?? ""}
                onChange={(e) => handleInputChange("Bairro", e.target.value)}
                name="Bairro"
                className="cs-form_field"
                required
              />
            </Div>

            <Div className="col-lg-6">
              <label className="form_field_label">Cidade:</label>
              <input
                type="text"
                value={formState.Cidade.value ?? ""}
                onChange={(e) => handleInputChange("Cidade", e.target.value)}
                name="Cidade"
                className="cs-form_field"
                required
              />
            </Div>

            <Div className="col-lg-6">
              <label className="form_field_label">Estado:</label>
              <input
                type="text"
                value={formState.Estado.value ?? ""}
                onChange={(e) => handleInputChange("Estado", e.target.value)}
                name="Estado"
                className="cs-form_field"
                required
              />
            </Div>

            <Div className="col-lg-6">
              <label className="form_field_label">Número:</label>
              <input
                type="text"
                value={formState.Numero.value ?? ""}
                onChange={(e) => handleInputChange("Numero", e.target.value)}
                name="Numero"
                className="cs-form_field"
                required
              />
            </Div>

            <Div className="col-lg-6">
              <label className="form_field_label">Complemento:</label>
              <input
                type="text"
                value={formState.Complemento.value ?? ""}
                onChange={(e) =>
                  handleInputChange("Complemento", e.target.value)
                }
                name="Complemento"
                className="cs-form_field"
                required
              />
            </Div>
          </Div>
        )}
      </Div>

      {/* Campos Condicionais: Preenchimento do Número */}
      {formState.Numero && (
        <Div className="row">
          {/* Placa */}
          <Div className="col-lg-6">
            <label className="form_field_label">Placa:</label>
            <InputMask
              mask="aaa-9*99"
              value={formState.placa.value ?? ""}
              onChange={(e) => handleInputChange("placa", e.target.value)}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  name="Placa"
                  className="cs-form_field"
                  required
                />
              )}
            </InputMask>
          </Div>

          {/* Estado de Retirada */}
          <Div className="col-lg-6">
            <label className="form_field_label">Estado de Retirada:</label>
            <select
              value={formState.estadoReserva.value ?? ""}
              onChange={(e) => {
                handleInputChange("estadoReserva", e.target.value)
                fetchCities(e.target.value)
              }}
              name="EstadoRetirada"
              className="cs-form_field"
              required
            >
              <option value="">Selecione um Estado</option>
              {states.map((state) => (
                <option key={state.id} value={state.sigla}>
                  {state.nome}
                </option>
              ))}
            </select>
          </Div>

          {/* Cidade de Retirada */}
          <Div className="col-lg-6">
            <label className="form_field_label">Cidade de Retirada:</label>
            <select
              value={formState.cidadeReserva.value ?? ""}
              onChange={(e) =>
                handleInputChange("cidadeReserva", e.target.value)
              }
              name="CidadeRetirada"
              className="cs-form_field"
              disabled={isSearchingCity}
              required
            >
              <option value="">Selecione uma Cidade</option>
              {cities.map((city) => (
                <option key={city.id} value={city.nome}>
                  {city.nome}
                </option>
              ))}
            </select>
          </Div>

          {/* Data de Retirada */}
          <Div className="col-lg-6">
            <label className="form_field_label">Data de Retirada:</label>
            <InputMask
              mask="99/99/9999"
              value={formState.dataRetirada.value ?? ""}
              onChange={(e) =>
                handleInputChange("dataRetirada", e.target.value)
              }
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  name="DataRetirada"
                  className="cs-form_field"
                  required
                />
              )}
            </InputMask>
          </Div>

          {/* Hora de Retirada */}
          <Div className="col-lg-6">
            <label className="form_field_label">Hora de Retirada:</label>
            <InputMask
              mask="99:99"
              value={formState.horaRetirada.value ?? ""}
              onChange={(e) =>
                handleInputChange("horaRetirada", e.target.value)
              }
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  name="HoraRetirada"
                  className="cs-form_field"
                  required
                />
              )}
            </InputMask>
          </Div>

          {/* Responsável pela Locação */}
          <Div className="col-lg-6">
            <label className="form_field_label">
              Nome do Responsável pela Locação:
            </label>
            <input
              type="text"
              value={formState.nomeResponsavel.value ?? ""}
              onChange={(e) =>
                handleInputChange("nomeResponsavel", e.target.value)
              }
              name="NomeResponsavelCartao"
              className="cs-form_field"
              required
            />
          </Div>

          {/* CPF do Responsável */}
          <Div className="col-lg-6">
            <label className="form_field_label">CPF do Responsável:</label>
            <InputMask
              mask="999.999.999-99"
              value={formState.cpfResponsavel.value ?? ""}
              onChange={(e) =>
                handleInputChange("cpfResponsavel", e.target.value)
              }
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  name="CPFResponsavelCartao"
                  className="cs-form_field"
                  required
                />
              )}
            </InputMask>
          </Div>

          {/* Diárias */}
          <Div className="col-lg-6">
            <label className="form_field_label">Diárias:</label>
            <input
              type="number"
              value={formState.diarias.value ?? ""}
              onChange={(e) => handleInputChange("diarias", e.target.value)}
              name="QuantidadeDeDiarias"
              className="cs-form_field"
              required
            />
          </Div>
        </Div>
      )}

      {/* Campos de Arquivos */}
      {formState.invoiced === false && (
        <Div className="row">
          {/* Arquivos */}
          {[
            "avisoSinistro",
            "boletimOcorrencia",
            "crlv",
            "cnh",
            "autorizacaoReparo",
          ].map((fileType, index) => (
            <Div key={index} className="col-lg-6">
              <label className="form_field_label">
                {fileType.replace(/([A-Z])/g, " $1").toUpperCase()}:
              </label>
              <FileInputPreview
                file={formState.arquivos[fileType]}
                name={fileType}
                setFile={(file) => {
                  console.log("Disparando ação para arquivo:", fileType, file)
                  dispatch({ type: "UPDATE_FILES", fileType, file })
                }}
              />
            </Div>
          ))}
        </Div>
      )}
    </>
  )
}

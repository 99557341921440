import Div from "../Div"
import Spacing from "../Spacing"
import { useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { CheckCircle } from "phosphor-react"
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext"
import { FileInputPreview } from "./FileInputPreview"
import { Loading } from "../loading"
import { sendFuneral } from "../../api/send-funeral"

export function FuneralForm() {
  const { keepLastFuneral } = useSolicitationStorage()
  const [sendingButton, setSendingButton] = useState("Solicitar benefício")
  const [funeralStatus, setfuneralStatus] = useState(null)

  const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)
  const [eidField, setEidField] = useState(null)

  const [funeralcnpj, setfuneralCnpj] = useState(null)
  const [funeralsolicitante, setfuneralSolicitante] = useState(null)
  const [funeraltelefone, setfuneralTelefone] = useState(null)
  const [funeralemail, setfuneralEmail] = useState(null)
  const [funeralnomesolicitante, setfuneralnomesolicitante] = useState(null)
  const [funeralcpfsolicitante, setfuneralcpfsolicitante] = useState(null)
  const [funeralemailsolicitante, setfuneralemailsolicitante] = useState(null)
  const [funeraltelefonesolicitante, setfuneraltelefonesolicitante] =
    useState(null)
  const [_, setfuneralttipo] = useState(null)
  const [funeralcapitalsegurado, setfuneralcapitalsegurado] = useState(null)

  const [funeralatestado, setfuneralatestado] = useState()
  const [funeralrgcpfbeneficiario, setfuneralrgcpfbeneficiario] = useState()
  const [funeralrgcpftitular, setfuneralrgcpftitular] = useState()
  const [funeralcomprovantebancario, setfuneralcomprovantebancario] = useState()
  const [funeralnotafiscal, setfuneralnotafiscal] = useState()

  const handleCNPJ = () => {
    if (funeralcnpj.length !== 18 && funeralcnpj.length !== 0) {
      toast.error("Campo CNPJ invalido.")
    } else {
      setIsSearchingCnpj(true)

      async function postJSON(data) {
        try {
          const response = await fetch(
            "https://brasilbeneficios.club/bb/f-empresa",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          )

          const result = await response.json()
          setEidField(result.IDCodigo)
          setfuneralEmail(result.Email)
          setfuneralSolicitante(result.RazaoSocial)
          setfuneralTelefone(result.Telefone)
        } catch (error) {
          toast.error("CNPJ invalido ou empresa nao encontrada.")
        }
      }

      postJSON({ CPFCNPJ: funeralcnpj })

      setIsSearchingCnpj(false)
    }
  }

  const handleFuneralSubmit = async (event) => {
    event.preventDefault()
    setSendingButton("Enviando...")

    const formData = new FormData(event.currentTarget)

    await sendFuneral(formData)

    fetch("https://brasilbeneficios.club/bb/area-cliente/funeral/send", {
      method: "POST",
      mode: "no-cors",
      credentials: "same-origin",
      body: formData,
    }).then((res) => {
      toast.success(
        "Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!"
      )

      for (const data of formData.entries()) {
        localStorage.setItem(data[0], data[1])
      }

      setfuneralStatus("Sended")
      localStorage.setItem("lastSolicitatedService", "Funeral")
    })
  }

  return (
    <>
      {funeralStatus === null ? (
        <Div className="fade-in col-lg-8">
          {keepLastFuneral ? (
            <form
              onSubmit={handleFuneralSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input
                type="hidden"
                name="IDEmpresa"
                value={localStorage.getItem("IDEmpresa")}
              />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setfuneralCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                    value={localStorage.getItem("cnpj")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="cnpj"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setfuneralSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={localStorage.getItem("SolicitanteEmpresa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setfuneralTelefone(e.target.value)}
                    value={localStorage.getItem("TelefoneEmpresa")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setfuneralEmail(e.target.value)}
                    name="EmailEmpresa"
                    value={localStorage.getItem("EmailEmpresa")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>
              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Nome do cliente:</label>
                  <input
                    type="text"
                    onChange={(e) => setfuneralnomesolicitante(e.target.value)}
                    name="Nome"
                    value={localStorage.getItem("Nome")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">CPF do cliente:</label>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=""
                    placeholder="___.___.___---"
                    onChange={(e) => setfuneralcpfsolicitante(e.target.value)}
                    value={localStorage.getItem("CPF")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CPF"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail do cliente:</label>
                  <input
                    type="email"
                    onChange={(e) => setfuneralemailsolicitante(e.target.value)}
                    name="Email"
                    value={localStorage.getItem("Email")}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Telefone do cliente:
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) =>
                      setfuneraltelefonesolicitante(e.target.value)
                    }
                    value={localStorage.getItem("Telefone")}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="Telefone"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">Tipo:</label>
                  <select
                    className="cs-form_field"
                    name="Tipo"
                    onChange={(e) => setfuneralttipo(e.target.value)}
                  >
                    <option
                      value=""
                      selected={
                        localStorage.getItem("Tipo") === "Multi-Familiar"
                          ? true
                          : false
                      }
                    >
                      Selecione uma opcao
                    </option>
                    <option
                      value="Multi-Familiar"
                      selected={
                        localStorage.getItem("Tipo") === "Multi-Familiar"
                          ? true
                          : false
                      }
                    >
                      Multi-Familiar
                    </option>
                    <option
                      value="Familiar"
                      selected={
                        localStorage.getItem("Tipo") === "Familiar"
                          ? true
                          : false
                      }
                    >
                      Familiar
                    </option>
                    <option
                      value="Individual"
                      selected={
                        localStorage.getItem("Tipo") === "Individual"
                          ? true
                          : false
                      }
                    >
                      Individual
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">Capital Segurado:</label>
                  <select
                    className="cs-form_field"
                    name="CapitalSegurado"
                    onChange={(e) => setfuneralcapitalsegurado(e.target.value)}
                  >
                    <option value="">Selecione uma opcao</option>
                    <option
                      value="2.000,00"
                      selected={
                        localStorage.getItem("CapitalSegurado") === "2.000,00"
                          ? true
                          : false
                      }
                    >
                      2.000,00
                    </option>
                    <option
                      value="3.000,00"
                      selected={
                        localStorage.getItem("CapitalSegurado") === "3.000,00"
                          ? true
                          : false
                      }
                    >
                      3.000,00
                    </option>
                    <option
                      value="5.000,00"
                      selected={
                        localStorage.getItem("CapitalSegurado") === "5.000,00"
                          ? true
                          : false
                      }
                    >
                      5.000,00
                    </option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Atestado de Obito:</label>
                  <FileInputPreview
                    file={funeralatestado}
                    name="Anexo1"
                    setFile={setfuneralatestado}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Cópia do RG e CPF do Beneficiário:
                  </label>
                  <FileInputPreview
                    file={funeralrgcpfbeneficiario}
                    name="Anexo2"
                    setFile={setfuneralrgcpfbeneficiario}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Cópia do RG e CPF do Titular:
                  </label>
                  <FileInputPreview
                    file={funeralrgcpftitular}
                    name="Anexo3"
                    setFile={setfuneralrgcpftitular}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Comprovante Bancário para Depósito:
                  </label>
                  <FileInputPreview
                    file={funeralcomprovantebancario}
                    name="Anexo4"
                    setFile={setfuneralcomprovantebancario}
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">Nota Fiscal:</label>
                  <FileInputPreview
                    file={funeralnotafiscal}
                    name="Anexo5"
                    setFile={setfuneralnotafiscal}
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              <button
                className="fade-in cs-btn cs-style1 cs-type1"
                type="submit"
              >
                <span>{sendingButton}</span>
              </button>
            </form>
          ) : (
            <form
              onSubmit={handleFuneralSubmit}
              method="post"
              encType="multipart/form-data"
            >
              <input type="hidden" name="IDEmpresa" value={eidField} />
              <Div className="row">
                <Div className="col-lg-6">
                  <div className="d-flex align-items-center gap-4">
                    <label className="form_field_label">CNPJ:</label>
                    {isSearchingCnpj && (
                      <div className="mb-2">
                        <Loading size="2px" />
                      </div>
                    )}
                  </div>
                  <InputMask
                    mask="99.999.999/9999-99"
                    maskChar=""
                    placeholder="__.___.___/____-__"
                    onChange={(e) => setfuneralCnpj(e.target.value)}
                    onBlur={handleCNPJ}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="cnpj"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Solicitante:</label>
                  <input
                    type="text"
                    onChange={(e) => setfuneralSolicitante(e.target.value)}
                    name="SolicitanteEmpresa"
                    value={funeralsolicitante ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">Telefone:</label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) => setfuneralTelefone(e.target.value)}
                    value={funeraltelefone ?? ""}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="TelefoneEmpresa"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail:</label>
                  <input
                    type="email"
                    onChange={(e) => setfuneralEmail(e.target.value)}
                    name="EmailEmpresa"
                    value={funeralemail ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>
              <Div className="row">
                <Div className="col-lg-6">
                  <label className="form_field_label">Nome do cliente:</label>
                  <input
                    type="text"
                    onChange={(e) => setfuneralnomesolicitante(e.target.value)}
                    name="Nome"
                    value={funeralnomesolicitante ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">CPF do cliente:</label>
                  <InputMask
                    mask="999.999.999-99"
                    maskChar=""
                    placeholder="___.___.___---"
                    onChange={(e) => setfuneralcpfsolicitante(e.target.value)}
                    value={funeralcpfsolicitante ?? ""}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="CPF"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">E-mail do cliente:</label>
                  <input
                    type="email"
                    onChange={(e) => setfuneralemailsolicitante(e.target.value)}
                    name="Email"
                    value={funeralemailsolicitante ?? ""}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">
                    Telefone do cliente:
                  </label>
                  <InputMask
                    mask="(99) 9 9999-9999"
                    maskChar=""
                    placeholder="(__) _ ____-____"
                    onChange={(e) =>
                      setfuneraltelefonesolicitante(e.target.value)
                    }
                    value={funeraltelefonesolicitante ?? ""}
                  >
                    {(inputProps) => (
                      <input
                        {...inputProps}
                        name="Telefone"
                        className="cs-form_field"
                      />
                    )}
                  </InputMask>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">Tipo:</label>
                  <select
                    className="cs-form_field"
                    name="Tipo"
                    onChange={(e) => setfuneralttipo(e.target.value)}
                  >
                    <option value="">Selecione uma opcao</option>
                    <option value="Multi-Familiar">Multi-Familiar</option>
                    <option value="Familiar">Familiar</option>
                    <option value="Individual">Individual</option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>

                <Div className="col-lg-6">
                  <label className="form_field_label">Capital Segurado:</label>
                  <select
                    className="cs-form_field"
                    name="CapitalSegurado"
                    onChange={(e) => setfuneralcapitalsegurado(e.target.value)}
                  >
                    <option value="">Selecione uma opcao</option>
                    <option value="2.000,00">2.000,00</option>
                    <option value="3.000,00">3.000,00</option>
                    <option value="5.000,00">5.000,00</option>
                  </select>
                  <Spacing lg="20" md="20" />
                </Div>
              </Div>

              {funeralcapitalsegurado && (
                <Div className="row">
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Atestado de Obito:
                    </label>
                    <FileInputPreview
                      file={funeralatestado}
                      name="Anexo1"
                      setFile={setfuneralatestado}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Cópia do RG e CPF do Beneficiário:
                    </label>
                    <FileInputPreview
                      file={funeralrgcpfbeneficiario}
                      name="Anexo2"
                      setFile={setfuneralrgcpfbeneficiario}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Cópia do RG e CPF do Titular:
                    </label>
                    <FileInputPreview
                      file={funeralrgcpftitular}
                      name="Anexo3"
                      setFile={setfuneralrgcpftitular}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                  <Div className="col-lg-6">
                    <label className="form_field_label">
                      Comprovante Bancário para Depósito:
                    </label>
                    <FileInputPreview
                      file={funeralcomprovantebancario}
                      name="Anexo4"
                      setFile={setfuneralcomprovantebancario}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>

                  <Div className="col-lg-6">
                    <label className="form_field_label">Nota Fiscal:</label>
                    <FileInputPreview
                      file={funeralnotafiscal}
                      name="Anexo5"
                      setFile={setfuneralnotafiscal}
                    />
                    <Spacing lg="20" md="20" />
                  </Div>
                </Div>
              )}

              {funeralnotafiscal && (
                <button
                  className="fade-in cs-btn cs-style1 cs-type1"
                  type="submit"
                >
                  <span>{sendingButton}</span>
                </button>
              )}
            </form>
          )}
        </Div>
      ) : (
        <Div>
          <CheckCircle size={50} />
          <h5 className="mt-3">Sucesso!</h5>
          <p>
            Sua solicitação foi realizada com sucesso!
            <br />
            Em até 48 horas entraremos em contato para fazer a liberação do seu
            benefício!
          </p>
          <button
            className="fade-in cs-btn cs-style1 cs-type1"
            type="button"
            onClick={(e) => window.location.reload()}
          >
            <span>Realizar outra solicitacao</span>
          </button>
        </Div>
      )}
    </>
  )
}
